<template>

    <div class="tables">
      <div style="margin: 0 0 30px 0">
        <el-button type="primary" @click="addRule">新增规则</el-button>
        <el-button @click="analysisBtn">解析测试</el-button>
        <el-button @click="deleteAll">批量删除</el-button>
      </div>
      <div style="flex: 1; overflow: auto">
        <el-table
          height="100%"
          :data="tableData"
          row-key="id"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          cell-style="text-align:center"
          header-cell-style="text-align:center;background:#EBEEF5"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column 
           v-for="(item, index) in col"
           :key="`col_${index}`"
           :prop="dropCol[index].prop"
           :label="item.label"
           :align="item.align"
          > 
            <template slot-scope="scope">
             <el-link :underline="false" v-if="item.label=='名称'">{{scope.row.analysisName}}</el-link>
             <p v-else>
                <span>{{scope.row[item.prop]}}</span>
             </p>
           </template>
          </el-table-column>
          <el-table-column prop="prop" label="操作" width="width">
            <template slot-scope="scope" >
              <el-button
                type="text"
                style="font-size: 13px; margin-right: 25px"
                @click="update(scope.row.id)"
                >编辑</el-button>
              <el-button
                type="text"
                style="font-size: 13px; margin-right: 25px"
                @click="deleted(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        style="text-align: center; border-top: 1px solid #e4e7ed; padding: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination></div>
        <!-- 新增规则 -->
        <el-dialog
          width="500px"
          :title="title"
          :visible.sync="addRuleBox"
          :close-on-click-modal="false"
        >
          <hr />
          <el-form
            :inline="true"
            ref="formInline"
            :model="formInline"
            class="demo-form-inline"
            label-position="right"
            label-width="70px"
          >
            <el-form-item label="名称" :rules="[ { required: true, message: '请输入名称', trigger: 'blur' }, ]" prop = "analysisName">
              <el-input
                placeholder=""
                v-model="formInline.analysisName"
               style="width:300px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="截取位数">
            <div style="display:flex">
              <el-select v-model="formInline.sliceType" placeholder="" class="rule_select" >
                <el-option
                  v-for="item in sliceTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            <el-input placeholder="请输入" v-model="formInline.sliceLength" class="select_input" v-show="formInline.sliceType == 2"></el-input>
            <el-input placeholder="请输入" v-model="formInline.sliceLength" class="select_input" v-show="formInline.sliceType == 3"></el-input>
            <!-- <el-input placeholder="请输入内容" v-model="formInline.sliceLength" class="select_input" v-show="formInline.sliceType == 4"></el-input> -->
            <div v-show="formInline.sliceType == 4" style="width:150px;display:flex;border: 1px solid #ddd;height:33px;border-radius: 4px;">
                <span>第</span>
                <div><el-input  v-model="formInline.sliceStart" class="tt"></el-input></div>
                <span>位起,截取</span>
                <div><el-input  v-model="formInline.sliceLength" class="tt"></el-input></div>
                <span>位</span>
            </div></div>
            </el-form-item>
            <el-form-item label="无效前缀">
              <el-select v-model="formInline.invalidPrefixType" placeholder="" class="rule_select">
                <el-option
                  v-for="item in invalidPrefixTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            <el-input placeholder="请输入内容" v-model="formInline.invalidPrefixSlice" class="select_input"></el-input>

            </el-form-item>
            <el-form-item label="无效后缀">
              <el-select v-model="formInline.invalidSuffixType" placeholder="" class="rule_select">
                <el-option
                  v-for="item in invalidSuffixTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            <el-input placeholder="请输入内容" v-model="formInline.invalidSuffixSlice" class="select_input"></el-input>

            </el-form-item>
            <el-form-item label="忽略字符">
            <el-input placeholder="请输入内容" v-model="formInline.ignoreChars" type="textarea" style="width:300px"></el-input>

            </el-form-item>
          </el-form>

          <div style="text-align: right; margin-top: 30px">
            <el-button @click="cancleBtn">取 消</el-button>
            <el-button type="primary" @click="sure" v-if="this.title == '新增规则'">确 定</el-button>
            <el-button type="primary" @click="updateSuccess"  v-if="this.title == '编辑规则'">确 定</el-button>

          </div>
        </el-dialog>
        <!-- 解析测试 -->
        <el-dialog
          width="500px"
          title="解析测试"
          :visible.sync="secondBox"
          :close-on-click-modal="false"
        >
          <hr />
          <el-form
            :inline="true"
            :model="secondObj"
            class="demo-form-inline"
            label-position="right"
            label-width="90px"
          >
            <el-form-item 
              label="MSKU"
              :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
              prop="msku">
              <el-input
                placeholder=""
                v-model="secondObj.msku"
                style="width:300px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="解析规则">
              <el-tooltip placement="top" effect="light" style="margin-left: -13px;">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>选择了则按指定规则解析，不选择按照规则优先级进行解析</p>
                        </div>
                        <span class="el-icon-question"></span>
              </el-tooltip>
              <el-select v-model="secondObj.id" placeholder="" class="rule_select" style="width:300px">
                <el-option
                  v-for="item in ruleList"
                  :key="item.id"
                  :label="item.analysisName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div style="margin-left:68px">
            <el-button type="primary" @click="analysisConfirm" style="background-color:#006eff;">解 析</el-button>
          </div>
          <div class="result" v-if="this.opensku == true">
              <div class="rule_result" v-if="this.resultmsg == '未解析出对应的本地SKU'">
                  未解析出对应的本地SKU
              </div>
              <div class="rule_results" v-if="this.resultmsg != '未解析出对应的本地SKU'">
                  <p>SKU：{{ this.resultmsg.sku }}</p> 
                  <p>解析规则：{{ this.resultmsg.ruleName }}</p>
              </div>
          </div>
        </el-dialog>
        <!-- 详情 -->
        <el-dialog
          width="500px"
          title="规则详情"
          :visible.sync="leftBox"
          :close-on-click-modal="false"
        >
          <hr />
          <el-form
            :inline="true"
            :model="secondObj"
            
            label-position="right"
            label-width="70px"
          >
            <el-form-item label="名称">
                ：{{formInline_left.analysisName}}
            </el-form-item><br/>
            <el-form-item label="截取位数">
                ：{{ formInline_left.sliceType }}
            </el-form-item><br/>
            <el-form-item label="无效前缀">
                ：{{ formInline_left.invalidPrefixTypeList == 1 ? "指定分隔符" : "指定位数" }}{{ formInline_left.invalidPrefixSlice }}
            </el-form-item><br/>
            <el-form-item label="无效后缀">
                ：{{ formInline_left.invalidPrefixTypeList == 1 ? "指定分隔符" : "指定位数" }}{{ formInline_left.invalidSuffixSlice }}
            </el-form-item><br/>
            <el-form-item label="忽略字符">
                ：<div class="wode">{{ formInline_left.ignoreChars }}</div>
            </el-form-item>
          </el-form>
          <div style="text-align: right; margin-top: 30px">
            <el-button @click="leftBox = false">取 消</el-button>
            <el-button type="primary" @click="left_update">编 辑</el-button>
          </div>
        </el-dialog>
      
    </div>
</template>

<script>
import { 
  Add,
  getlistPage,
  deleteRule, 
  getdetail, 
  update, 
  updatestatus, 
  batchDelete, 
  pageInit, 
  handleSort 
} from '@/api/goodsManage/analysisRule';
import Sortable from 'sortablejs'
export default {
  data() {
    return {
      tableData: [],
      title:'',
      value1:'',
      opensku:false,
      addRuleBox:false,
      secondBox:false,
      pageInfo: {
        pageSize: "",
      },
      ruleList:[],
      col: [
        { label: '名称', prop: 'analysisName', align:"center"},
        { label: '操作人', prop: 'updatePerson', align:"center" },
        { label: '操作时间', prop: 'updateTime', align:'center' }
      ],
      dropCol: [
        { label: '名称', prop: 'analysisName' },
        { label: '操作人', prop: 'updatePerson' },
        { label: '操作时间', prop: 'updateTime' }
      ],
      sliceTypeList: [
        {
          value: 1,
          label: "不截取",
        },
        {
          value: 2,
          label: "从左到右截取",
        },
        {
          value: 3,
          label: "从右到左截取",
        },
        {
          value: 4,
          label: "指定范围",
        },
      ],
      invalidPrefixTypeList:[
           {
          value: 1,
          label: "指定分隔符",
        },
        {
          value: 2,
          label: "指定位数",
        },
      ],
      invalidSuffixTypeList:[
           {
          value: 1,
          label: "指定分隔符",
        },
        {
          value: 2,
          label: "指定分数",
        },
      ],
      formInline:{
          sliceType: '',
          analysisName:'',
          invalidPrefixType: '',
          invalidSuffixType: '',
          invalidSuffixSlice:'',
          invalidPrefixSlice:'',
          sliceLength:'',
          sliceStart:'0',
          ignoreChars:''

      },
      secondObj:{
          msku:'',
          id:'',
      },
      resultmsg:'',
      code:'',
      ids:[],
      multipleSelection:[],
      formInline_left:{},
      leftBox:false,
      myid:'',
    };
  },
  created(){
      this.getData();
  },
  mounted(){
    // 阻止默认行为
	  document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    this.rowDrop();
  },
  methods:{
    //优先级排序
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];  
          _this.tableData.splice(newIndex, 0, currRow); 
          let sortedIds = [];
          sortedIds = _this.tableData.map(v=> v.id);
          _this.handleProceSort(currRow, sortedIds); 
        }
      })
    },
    handleProceSort(currRow, sortedIds) {
      let data = {
        currentId: currRow.id,
        ids: sortedIds,
      }
      handleSort(data).then(res=> {
        if(res.data.code == 500) {
         this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);
        }
      })
      
    },
      //新增
      addRule(){
          this.formInline = {
          sliceType: 1,
          analysisName:'',
          invalidPrefixType: 1,
          invalidSuffixType: 1,
          invalidSuffixSlice:'',
          invalidPrefixSlice:'',
          sliceLength:'',
          sliceStart:'0',
          ignoreChars:''
          }
          this.title = "新增规则";
          this.addRuleBox = true;
      },
      cancleBtn(){
        this.addRuleBox = false;
      },
      //新增确认
      sure(){
        this.$refs.formInline.validate((valid) => {
        if (valid) {
          this.loading = true;
          Add(this.formInline).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.addRuleBox = false;
              this.getData();
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      },
      //解析
      analysisBtn(){
        this.secondObj = {
          msku:'',
          id:'',
      },
      this.opensku = false,
        this.secondBox = true;
        pageInit().then((res) => {
          this.ruleList = res.data;
      });
      },
    //页面初始化
    async getData() {
      this.loading = true;
      try {
        const res = await getlistPage();
        if ((Object.is(res.code), 200)) {
          this.loading = false;
          this.tableData = res.data.values;
          this.total = res.data.pageInfo.total;
        } else {
          this.$message.error(res.message);
          this.loading = false;
        }
      } catch (error) {}
    },
    //删除
    deleted(id){
        this.$confirm("确定删除该数据？删除后数据不可恢复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteRule(id).then((res) => {
          if (res.data.code == 200) {
            this.getData();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
      });
    },
    //编辑
    update(id){
        this.addRuleBox = true;
        this.title = "编辑规则"
        getdetail(id).then((res) => {
            this.formInline = res.data.data
      });
    },
    //编辑确认
    updateSuccess(){
        update(this.formInline).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.addRuleBox = false;
              this.getData();
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          });
    },
    //表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.ids = [];
      this.multipleSelection.forEach((data) => {
      this.ids.push(data.id);
      });
    },
    //批量删除
    deleteAll(){
        if (this.multipleSelection.length == 0) {
        this.$message.error("请先勾选需要批量操作对象");
        return false;
      }
      let data = {
          ids:this.ids,
        }
      batchDelete(data).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getData()
          } else {
            this.$message.warning(res.data.message);
          }
        });
    },

    //解析确认
    analysisConfirm(){
        updatestatus(this.secondObj).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.code = res.data.code
              this.opensku = true;
              this.resultmsg = res.data.data;
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          });
    },
    //详情
    getDetail_left(id){
        this.leftBox= true;
        this.myid = id
        getdetail(id).then((res) => {
            this.formInline_left = res.data.data;
            if(res.data.data.sliceType == 1){
                this.formInline_left.sliceType = "不截取"
            }else if(res.data.data.sliceType == 2){
                this.formInline_left.sliceType  = "从左到右截取 " + this.formInline_left.sliceLength +' 位'
            }else if(res.data.data.sliceType == 3){
                this.formInline_left.sliceType  = "从右到左截取 " + this.formInline_left.sliceLength +' 位'
            }else if(res.data.data.sliceType == 4){
                this.formInline_left.sliceType  = "从第 " + this.formInline_left.sliceStart +' 位起，截取 ' + this.formInline_left.sliceLength+' 位'
            }else if(res.data.data.invalidPrefixType == 1){
                this.formInline_left.invalidPrefixSlice  = "指定分隔符 " + this.formInline_left.invalidPrefixSlice
            }else if(res.data.data.invalidPrefixType == 2){
                this.formInline_left.invalidPrefixSlice  = "指定位数 " + this.formInline_left.invalidPrefixSlice
            }else if(res.data.data.invalidSuffixType == 1){
                this.formInline_left.invalidSuffixSlice  = "指定分隔符 " + this.formInline_left.invalidSuffixSlice
            }else if(res.data.data.invalidSuffixType == 2){
                this.formInline_left.invalidSuffixSlice  = "指定位数 " + this.formInline_left.invalidSuffixSlice
            }

      });
    },
    //详情编辑
    left_update(){
        this.leftBox= false;
         this.addRuleBox = true;
        this.title = "编辑规则"
        getdetail(this.myid).then((res) => {
            this.formInline = res.data.data
      });
    },
  }
};
</script>

<style scoped lang='scss'>
.tables {
  width: 100%;
  height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
}
.select_input{
    width: 150px;
}
.rule_select{
    width: 150px;
}
.result{
    background-color: #ddd;
    border: 1px solid #ddd;
    height: 100px;
    width: 300px;
    margin: 20px 0 0 67px;
}
.rule_result{
    height: 20px;
    line-height: 20px;
    margin-top: 5px;
    
}
.rule_results{
    display: flex;
    height: 20px;
    line-height: 20px;
    margin-top: 5px;
    flex-direction: column;
    align-items: flex-start;
}
.tt{
    width: 30px;
}
 
::v-deep .tt .el-input__inner {
  border: 0px;
  padding: 0 0 1px 2px;
  height: 28px;
}
.wode{
    line-height: 18px;
    background: #eee;
    border-radius: 2px;
    display: inline-block;
    padding: 0 4px;
}
</style>