import request from '@/utils/request.js'
//新增
export function Add(data) {
    return request({
        url:'/api/analysisrules/add',
        method:'post',
        data
    });
}
//查询
export function getlistPage(params) {
    return request({
        url:'/api/analysisrules/listPage',
        method:'get',
        params
      
    });
}
//删除
export function deleteRule(id) {
    return request({
      url: "/api/analysisrules/delete/" + id,
      method: "delete"
    })
}
//详情
export function getdetail(id) {
    return request({
        url:'/api/analysisrules/detail/' + id,
        method:'get',
      
    });
}
//修改确认
export function update(data) {
    return request({
      url: "/api/analysisrules/update",
      data,
      method: "put"
    })
  }
//解析测试
export function updatestatus(data) {
    return request({
        url:'/api/analysisrules/analysisTest',
        method:'post',
        data
    });
}
//批量操作
export function batchDelete(data) {
    return request({
        url:'/api/analysisrules/batchDelete',
        method:'post',
        data
    });
}
//解析测试初始化
export function pageInit(params) {
    return request({
        url:'/api/analysisrules/pageInit',
        method:'get',
        params
      
    });
}
//排序
export function handleSort(data) {
    return request({
        url:'/api/analysisrules/sort',
        method:'post',
        data
    });
}